import React from 'react';
import Layout from '../../components/layout/Layout';
import ContentAM from '../../components/ContentAM';
import PageNavigator from "../../components/layout/PageNavigator";
import license from '../../data/corsi-patente/patente-B96/license.json';
import Seo from '../../components/layout/SEO';
import BannerCompass from '../../components/BannerCompass';


const PatentiB96 = () => {
  return (
    <Layout>
      <Seo
        title={"Corsi per Patente B96 o BE | Autoscuola Faro, Pisa"}
        description={"Consegui la patente B96 o BE per attaccare alla tua auto rimorchi o carrelli per barche o moto."}
        keywords={[
          "Corso Patente B96 Pisa",
          "Corso Patente BE Pisa",
          "Corsi Patente B96 Pisa",
          "Corsi Patente BE Pisa",
          "Conseguire Patente B96 Pisa",
          "Conseguire Patente BE Pisa"
        ]}
      />
      <PageNavigator link="/corsi-patente" text="Corsi patenti" />
      <ContentAM license={license} />
      <BannerCompass />
    </Layout>
  )
};

export default PatentiB96;
