import React from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';



const ContentAM = ({ id, license, vehicles }) => {
  return (
    <section id={id} className="text-gray-600 body-font">
      <div className="container px-8 sm:px-5 py-12 pb-56 mx-auto flex flex-col">
        <div className="lg:w-full mx-auto">
          <h1 className="text-center sm:text-6xl text-4xl font-roboto font-bold tracking-tight text-gray-900">{license.title}</h1>
          <div className="flex flex-col sm:flex-row mt-4 sm:mt-10">
            <div className="sm:w-full sm:pl-8 sm:py-8 mt-4 pt-4 sm:mt-0 text-left sm:text-left">
              <div className="sm:mb-4 mb-4 px-8 py-2">
                <h2 className="text-center text-2xl font-roboto font-bold tracking-tight text-gray-900">{license.vehicleDescription}</h2>
                <p className="text-center font-roboto font-bold tracking-tight text-gray-900">{license.transmission}</p>
              </div>
              {license.vehicles.map((v) => (
                <div key={v.id} className="sm:w-full text-center sm:py-8 py-8">


                  {/* Image */}
                  {v.vehicleImg !== null ?
                    <div className="w-84 h-64 rounded-lg inline-flex items-center justify-center bg-gray-200 text-gray-400"><img
                      alt={v.vehicleImg}
                      className="w-full h-full rounded-xl block inset-0"
                      src={v.vehicleImg}
                    />
                    </div> :
                    null
                  }
                  {/* <StaticImage
                      alt={v.vehicleImg}
                      className="rounded-full w-full h-full block inset-0"
                      src="../images/boat.jpg"
                    /> */}
                
                  {/* Description */ }
                < div className = "flex flex-col items-center text-center justify-center" >
                    <div className={`w-12 h-1 bg-gray-900 rounded mt-2 mb-4`}></div>
                    <h2 className="title-font font-roboto font-bold tracking-tight text-gray-900 text-lg px-16 py-2">
                      {v.text}
                    </h2>
                  </div>
          </div>
              ))}
        </div>

        <div className="order-first sm:order-last sm:w-full sm:pl-8 sm:py-8 sm:border-l sm:border-gray-400 sm:border-t-0 border-t border-gray-400 mt-4 pt-4 sm:mt-0 text-left sm:text-left">
          <h2 className="text-3xl title-font mb-4 mt-4 font-roboto font-bold tracking-tight text-gray-900">
            {license.ageLabel}
          </h2>
          {license.minAge.map((el) => (
            <p id={el.id} className="leading-relaxed text-lg mb-4 font-roboto font-bold tracking-tight text-gray-900">{el.text}</p>
          ))}
          <h2 className="text-3xl title-font mb-4 font-roboto font-bold tracking-tight text-gray-900">
            {license.licenseFor}
          </h2>

          <ul className="list-disc sm:ml-8 mt-4 ml-4">
            {license.vehicleTypes.map((el) => (

              <li className="leading-relaxed text-lg mb-4 font-roboto font-bold tracking-tight text-gray-900">{el.text}
                <ol id={el.id} className="list-disc sm:ml-8">
                  {el.characteristics.map((char) => (
                    <li id={char.id}>{char.text}</li>
                  ))}
                </ol>
              </li>

            ))}
          </ul>

          {/* Document section */}
          <h2 className="text-3xl title-font mt-16 font-roboto font-bold tracking-tight text-gray-900">
            {license.documentsLabel}
          </h2>
          <ul className="list-disc sm:ml-8 mt-4 ml-4">
            {license.docs.map((el) => (
              <li id={el.id} className="leading-relaxed text-lg mb-4 font-roboto font-bold tracking-tight text-gray-900">
                {el.text}
              </li>
            ))}
          </ul>
        </div>

      </div>
    </div>
      </div >
    </section >
  );
};

ContentAM.defaultProps = {
  theme: 'indigo',
};

ContentAM.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default ContentAM;
