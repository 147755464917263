import React from "react";
import PagoLight from "../../static/PagoLight.jpg";

const BannerCompass = () => {
  return (
    <div className="bg-blue-accent-400">
      <div className="px-4 py-16 mx-auto 
      sm:max-w-xl md:max-w-full lg:max-w-screen-xl 
      md:px-24 lg:px-8 lg:py-20 grid gap-12 
      row-gap-8 lg:grid-cols-2 items-start">
        <div className="flex flex-col justify-center lg:order-last md:order-last order-last">
          <div className="max-w-xl mb-6">
            <h2 className="max-w-lg mb-6 font-sans text-4xl 
            font-extrabold tracking-tight text-white sm:text-5xl sm:leading-none">
              Autoscuola Faro e <span className="text-white">Pagolight</span>: il duo perfetto per una
              <span className=" text-gray-900">
                {" "}
                guida conveniente!
              </span>{" "}
              <br className="block md:block sm:block" />
            </h2>
            <p className="text-2xl text-white font-sans font-medium">
              Rendi la tua esperienza di apprendimento della guida più agevole
              con i pagamenti dilazionati offerti da Pagolight di Compass
            </p>
          </div>
        </div>
        <div className="flex items-center justify-center lg:pl-8">
          <div className="w-3/4 h-3/4 flex flex-col items-end px-3 rounded-lg">
            <img src={PagoLight} alt="" className="object-cover rounded-lg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerCompass;
